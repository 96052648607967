import "web-locks-polyfill"

window.PostBuffer = class {
	constructor(userId, csrfToken) {
		this.userId = userId
		this.csrfToken = csrfToken
		this.appEnvironment = window.appEnvironment
		localStorage.postBufferUserId = userId // for detecting user switches outside of this tab

		// fired when the entire page has loaded, including all dependent resources such as stylesheets, scripts, iframes, and images
		window.addEventListener("load", () => {
			//if (this.appEnvironment != "production") console.log("PostBuffer started!")

			// fired when the browser has gained access to the network
			window.addEventListener("online", () => {
				window.postBuffer.flush()
			})

			// fired every 5 seconds
			setInterval(() => {
				window.postBuffer.flush()
			}, 5_000)

			window.postBuffer.flush()
		})
	}

	/**
	 * Send a message, or add it to the buffer
	 * @param {string} path
	 * @param {mixed} data will be JSON encoded when sending
	 * @param {string} method default: "POST"
	 * @returns {object|null} server response body plus `http_status`, or `null` if no immediate response or bad response
	 */
	async send(path, data = {}, method = "POST") {
		let entry

		if (path.charAt(0) != "/") path = "/" + path

		if ((await this.flush()) == true) {
			entry = { user_id: this.userId, path, data, method }
			let response = await this._send_now(entry)

			if (response != null)
				if (response.http_status == 401 /* unauthorized */) this._stop = true
				else return response
		}

		// This mutex is used to enforce one-at-a-time access to `localStorage.postBufferBuffer`
		navigator.locks.request("postBufferBuffer", () => {
			let buffer = JSON.parse(localStorage.postBufferBuffer ?? "[]")
			entry = { user_id: this.userId, path, data, method }
			buffer.push(entry)
			localStorage.postBufferBuffer = JSON.stringify(buffer)
		})

		return null
	}

	/**
	 * Attempt to flush the buffer
	 * @returns {bool|null} whether the buffer was successfully flushed or `null` if flushing is currently in progress or if a `stop` state was reached
	 * @event `postBufferSent` is dispatched to `window` when a message is successfully sent. It contains the buffer entry that was sent
	 * @event `postBufferFlushed` is dispatched to `window` when the buffer is successfully flushed; even if there are still entries. It contains the buffer entries that were sent during this run of `this.flush`
	 */
	async flush() {
		// This mutex is used to enforce one-at-a-time running of `this.flush` to prevent network races
		return await navigator.locks.request("postBufferFlushing", { ifAvailable: true }, async (lock) => {
			if (lock === null) return null // the lock is taken; flushing is currently in progress

			return await navigator.locks.request("postBufferBuffer", async () => {
				if (this._stop == true) return null

				let buffer = JSON.parse(localStorage.postBufferBuffer ?? "[]")
				let sent = []
				let success = true

				for (let i = 0; i < buffer.length; i++) {
					let entry = buffer[i]
					if (this.userId != localStorage.postBufferUserId) return null // we aren't who we think we are
					if (entry.user_id != this.userId) continue
					let response = await this._send_now(entry)

					if (response?.http_status == 401 /* unauthorized */) {
						this._stop = true
						break
					}

					if (response != null) {
						window.dispatchEvent(new CustomEvent("postBufferSent", { detail: entry }))
						sent.push(entry)
						buffer[i] = undefined
					} else {
						success = false
					}
				}

				buffer = buffer.filter((entry) => entry != undefined)
				localStorage.postBufferBuffer = JSON.stringify(buffer)

				if (sent.length > 0) window.dispatchEvent(new CustomEvent("postBufferFlushed", { detail: sent }))

				return success
			})
		})
	}

	/***********************************
	 * Private stuff below this line *
	 ***********************************/

	_stop = false

	/**
	 * Send message
	 * @param {object} bufferEntry
	 * @return {object|null} server response body plus `http_status`, or `null` if no response
	 */
	async _send_now(entry) {
		if (this.userId != localStorage.postBufferUserId) return false // we aren't who we think we are
		if (entry.user_id != this.userId) return false // we aren't who we're trying to send as

		try {
			let response = await fetch(entry.path, {
				method: entry.method,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"X-CSRF-Token": this.csrfToken,
				},
				redirect: "error",
				body: JSON.stringify(entry.data),
			})

			response = await response.json()
			response.http_status = response.status
			return response
		} catch (e) {
			return null
		}
	}
}
